import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Campfire is a threaded discussion platform I built as an extension of a larger content management system for online course content at the University level. This tool allows users to post and comment inside of threaded discussion forums in a simple, easy-to-use fashion. It also allows professors to:`}</p>
    <ul>
      <li parentName="ul">{`Manage forums and topics inside of a master course so the same structure can easily be reused in many sections of the course.`}</li>
      <li parentName="ul">{`Set up grading including a few different aggregation methods`}</li>
      <li parentName="ul">{`Score individual posts`}</li>
      <li parentName="ul">{`View score aggregates for a particular forum`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/work/campfire-forum.png",
        "alt": null
      }}></img></p>
    <p>{`Campfire uses PHP/MySQL with the CodeIgniter framework delivering basic pages and JSON data as an API. Most of the functionality is accomplished using AngularJS to consume and operate on the API data.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/campfire-edit-form.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      